import { createSlice } from "@reduxjs/toolkit";

const initState = {
	tapLanguage: "arabic",
};

const LanguageTapsReducer = createSlice({
	name: "LanguageTaps",

	initialState: initState,
	reducers: {
		changeTapLanguage: (state, action) => {
			state.tapLanguage = action?.payload;
		},
	},
});

export default LanguageTapsReducer.reducer;

export const { changeTapLanguage, defaultTapLanguage } =
	LanguageTapsReducer.actions;
